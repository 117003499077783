import React from 'react'
import ReactDOM from 'react-dom'
import './scss/style.scss'
import App from './App'
import reportWebVitals from './reportWebVitals'

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
)

// eslint-disable-next-line no-console
reportWebVitals(console.log)
