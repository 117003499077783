import React from "react";
import { v4 as uuidv4 } from "uuid";
import ServicesItem from "./Service";

const servicesItems = [
  {
    name: "Коворкинг",
    description: `Заключив договор на пользование коворкингом, вы
    получаете полностью оборудованое рабочее место, доступ к
    высокоскоростному интернету, а также возможность использовать
    дополнительные преимущества ИТ-парка (комнату отдыха,
    обеденную, орг технику и многое другое).`,
  },
  {
    name: "Провести мероприятие",
    description: `Вы можете провести свое мероприятие на трерритории
    ИТ-парка, для этого просто заполните форму, и наш специалист
    свяжется с вами для уточнения деталей.`,
  },
  {
    name: "Стать нашим резидентом",
    description: `Если вы работаете в ИТ сфере или реализуете ИТ проекты,
    вы можете стать резидентом ИТ-парка. Мы сделаем все, чтобы помочь
    вашему проекту реализоваться.`,
  },
  {
    name: "Консультация специалиста",
    description: `Есть вопрос в сфере ИТ? Оставьте заявку, и наш специалист
    сможет вас проконсультировать по вашему проекту. `,
  },
];

const Services = () => (
  <ul className="services__list">
    {servicesItems.map((item) => (
      <ServicesItem
        key={uuidv4()}
        name={item.name}
        description={item.description}
      />
    ))}
  </ul>
);

export default Services;
